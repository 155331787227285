<template>
  <main class="forgotPassword-page flex justify-center items-center h-screen">
    <div
      class="xl:w-2/3 lg:w-2/3 md:w-3/4 sm:w-11/12 sm:mx-4 rounded shadow-card grid xl:grid-cols-2 lg:grid-cols-2"
    >
      <div
        class="logo-images flex justify-center items-center content-center bg-light-gray"
      >
        <img
          src="@/assets/images/online-icon.png"
          alt="Xlinx Logo"
          class="mr-2 align-top"
        />
        <img
          src="@/assets/images/prolope-icon.svg"
          alt="Xlinx Logo"
          class="mr-2 image2 mt-2"
        />
        <img
          src="@/assets/images/group.svg"
          alt="Xlinx Logo group"
          class="mr-2 image3 align-baseline mt-2"
        />
      </div>

      <div class="font-sans p-8 text-left">
        <div class="mb-8">
          <h4 class="text-2xl font-bold">
            {{ $t("heading.pages.forgot-password.recover") }}
          </h4>
          <p class="mt-2 text-dark-gray">
            {{ $t("text.pages.forgot-password.enter_email") }}
          </p>
        </div>

        <form
          action="#"
          @submit.prevent="submit"
          @keyup="removeError($event.target.name)"
          @change="removeError($event.target.name)"
        >
          <div class="form-group pb-3">
            <input
              type="email"
              name="email"
              placeholder="e-mail"
              class="block border-0 border-b-half w-full focus:outline-none"
              :class="[
                !!errors.email
                  ? ['border-red-900 placeholder-red-900']
                  : ['border-light-green placeholder-gray-500'],
              ]"
              v-model="form.email"
            />

            <label
              for="name"
              class="text-xs"
              :class="!!errors.email ? 'text-red-900' : 'text-gray-500'"
            >
              {{ !!errors.email ? errors.email[0] : "Type your e-mail" }}
            </label>
          </div>

          <div class="flex justify-between mt-10">
            <router-link
              :to="{ name: 'login' }"
              class="bg-white text-dark-green border border-dark-green hover:bg-dark-green hover:text-white p-1 px-10 text-center transition ease-in duration-150"
            >
              Login
            </router-link>

            <button
              class="bg-dark-green text-gray-200 p-1 px-10 text-center transition ease-in duration-100 hover:opacity-90"
              :disabled="loading"
            >
              <span class="inline">{{
                $t("button.pages.forgot-password.recover")
              }}</span>
              <font-awesome-icon
                icon="circle-notch"
                class="fa-spin ml-1"
                v-show="loading"
              />
            </button>
          </div>
        </form>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "forgotPassword",
};
</script>

<script setup>
import { reactive, ref, computed, inject } from "vue";
import validator from "@/composables/auth/validator";
import usePasswords from "@/composables/auth/usePasswords";

const form = reactive({
  email: "",
});

const loading = ref(false);
const notify = inject("notify");
const t = inject("t");
const { removeError, clearErrors, validate, errors } = validator();
const { forgotPassword } = usePasswords();

const hasErrors = computed(() => {
  return Object.values(errors.value).length;
});

const submit = async () => {
  validate({
    data: form,
    rules: {
      email: ["required", "email"],
    },
  });

  if (hasErrors.value) {
    notify({
      message: t("notify.profile_information.fix_errors"),
      type: "danger",
    });
    return;
  }

  loading.value = true;

  try {
    const response = await forgotPassword(form);
    loading.value = false;
    if (response == "We can't find a user with that email address.") {
      notify({
        message: t("notify.profile.forgot-password.not_found"),
        type: "danger",
      });
      return;
    }
    clearErrors();
    form.email = "";
    notify({
      message: t("notify.profile.forgot-password.reset_link"),
    });
  } catch (e) {
    loading.value = false;
    notify({
      message: t("validation.main.pages.forgotPassword.error_msg"),
      type: "danger",
    });
    throw e;
  }
};
</script>

<style scoped lang="scss">
.logo-images {
  .image2 {
    width: 200px;
    height: 30px;
    @media (max-width: 1200px) {
      width: 170px;
    }
    @media (max-width: 900px) {
      width: 150px;
    }
    @media (max-width: 600px) {
      width: 120px;
    }
  }
  .image3 {
    width: 103px;
    height: 30px;
    @media (max-width: 1200px) {
      width: 90px;
    }
    @media (max-width: 900px) {
      width: 80px;
    }
    @media (max-width: 600px) {
      width: 70px;
    }
  }
}

.register-icon {
  width: 300px;
  height: 300px;
  @media (max-width: 1200px) {
    width: 250px;
    height: 250px;
  }
  @media (max-width: 600px) {
    width: 200px;
    height: 200px;
  }
}
.border-b-half {
  border-bottom-width: 0.5px;
}
</style>
